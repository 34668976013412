<template>
  <div class="details" v-loading="isLoading">
    <el-breadcrumb separator="/" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/routerPartsRetrieval/partsRetrievalListPage' }"
        >零配件检索</el-breadcrumb-item
      >
        <!-- :to="{
          path: `/routerPolicyManagement/${this.$route.meta.policyRoute}/policyManagement`,
        }" -->
      <el-breadcrumb-item :to="{ path: '/routerPartsRetrieval/partsRetrievalListPage?partBrand='+detailsData.partBrand }"
        >{{ detailsData.partBrand || '-' }}</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ detailsData.deviceType || '-' }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="details-box">
      <div class="details-box-info">
        <el-image
          style="width: 400px; height: 400px"
          :src="detailsData.partImage || require('@/assets/images/partsRetrieval/parts-defaultIcon.png')"
          :preview-src-list="[detailsData.partImage]"
        >
        </el-image>
        <div class="details-box-info-item">
          <div class="details-box-info-item-title">
            {{ detailsData.partName }}
          </div>
          <div class="details-box-info-item-xh">
            配件型号：{{ detailsData.partModel }}
          </div>
          <div class="details-box-info-item-jg">
            供应商报价
            <span
              >{{ detailsData.supplierQuotation }}</span
            >
          </div>
          <div class="details-box-info-item-cell">
            <div>配件品牌</div>
            {{ detailsData.partBrand }}
          </div>
          <div class="details-box-info-item-cell">
            <div>设备种类</div>
            {{ detailsData.deviceCategory }}
          </div>
          <div class="details-box-info-item-cell">
            <div>设备型号</div>
            {{ detailsData.deviceModelNumber }}
          </div>
          <div class="details-box-info-item-cell">
            <div>备注</div>
            {{ detailsData.remark }}
          </div>
        </div>
      </div>
      <div class="details-box-title">供应商列表</div>
      <el-table :data="detailsData.supplierList ? detailsData.supplierList : []" border style="width: 845px;margin: auto;">
        <el-table-column prop="supplierName" label="供应商名称" width="218">
        </el-table-column>
        <el-table-column prop="partType" label="零件类型" width="80">
           <template slot-scope="scope">
            {{ partTypeList[scope.row.partType] }}
          </template>
        </el-table-column>
        <el-table-column prop="partOffer" label="零件报价" width="80">
           <!-- <template slot-scope="scope">
            {{ partTypeList[scope.row.partType] }}
          </template> -->
       </el-table-column>
        <el-table-column prop="supplierContact" label="供应商联系方式" width="100">
        </el-table-column>
        <el-table-column prop="supplierAddress" label="供应商地址">
          <template slot-scope="scope">
            <div style="display:flex;align-items: center;">
              <div>{{ scope.row.supplierAddress }}</div>
              <img
                src="@/assets/images/partsRetrieval/supplierAddressIcon.png" @click="handleOpenMap(scope.row)"
                style="
                background-color: #fff;
                padding: 0 5px;
                width: 20px;
                height: 20px;cursor: pointer;
              "
                alt
                srcset
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <mapPopup v-model="isShowMap" :details="mapData" />
  </div>
</template>

<script>
import { getZeroPartsDetails } from "@/api/basicData"
import mapPopup from "@/views/PartsRetrieval/components/mapPopup.vue"
export default {
  data() {
    return {
      detailsData: {},
      isShowMap: false,
      mapData: {},
      partTypeList: [
        '',
        '原厂件',
        '副厂件',
        '拆车件',
        '厂商/代理商服务',
        '外修厂服务'
      ],
      isLoading: false,
    };
  },
  components: { mapPopup },
  created() {
    this.init()
  },
  methods : {
    init() {
      this.isLoading = true;
      getZeroPartsDetails({
        id: this.$route.query.id,
        userId: this.$store.state.userInfo.MJUserId
      }).then(res=>{
        this.isLoading = false;
        console.log(res);
        this.detailsData = res.data || {};
      }).catch(err=>{
        this.isLoading = true;
      })
    },
    handleOpenMap(e) {
      if (!e.lat) {
        this.$message.error('暂无定位')
        return false;
      }
      this.isShowMap = true;
      this.mapData = e;
    }
  }
};
</script>

<style lang="scss" scoped>
.details {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 24px;
  &-box {
    flex: 1;
    padding: 26px;
    margin-top: 10px;
    background-color: #fff;
    border-radius: 8px;
    &-info {
      display: flex;
      width: 845px;
      margin: auto;
      padding-bottom: 24px;
      border-bottom: 2px solid #f5f5f5;
      &-item {
        margin-left: 32px;
        flex: 1;
        &-title {
          font-size: 20px;
          font-weight: bold;
          color: #333333;
        }
        &-xh {
          font-size: 14px;
          margin-top: 8px;
          color: #4278c9;
        }
        &-jg {
          width: 100%;
          height: 52px;
          line-height: 52px;
          font-style: 14px;
          color: #333333;
          padding: 0 12px;
          background: #f2f7fd;
          margin-top: 24px;
          span {
            font-size: 20px;
            font-weight: bold;
            color: #fda835;
            margin-left: 16px;
          }
        }
        &-cell {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          margin-top: 24px;
          div {
            width: 98px;
            font-size: 14px;
            color: #999999;
          }
        }
      }
    }
    &-title {
      position: relative;
      width: 845px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      padding-left: 14px;
      margin: 24px auto;
    }
    &-title::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      width: 6px;
      border-radius: 3px 0 0 3px;
      background-color: rgba(66, 120, 201, 1);
    }
    ::v-deep .has-gutter {
      tr {
        th {
          background: #F2F7FD;
          font-size: 14px;
          color: #666666;
        }
      }
    }
  }
}
</style>