<template>
  <el-dialog title="位置"
    :visible.sync="dialogVisible" append-to-body
    custom-class="policy-import-dialog"
    width="640px"
    :before-close="handleClose">
    <div class="statistical-box">
         <div class="statistical-box-decoration-down" ref="container">
         </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">返 回</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    showDialog: Boolean,
    details: {
      type: Object,
      default: ()=>{}
    },
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }else {
            this.decorationIndex = 1;
          this.$emit('change', true);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          this.$nextTick(()=>{
            this.init();
          })
        }else {
          this.dialogVisible = false;
        }
      }
    },
  },
  data() {
    return {
      dialogVisible: false,
      decorationIndex: 1,
      marker: null,
      mapall: null,
      fileList: []
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    init() {
      const that = this;
      let Lng = '';
      let Lat = "";
      if (this.details.lat) {
         Lng = this.details.lon;
         Lat = this.details.lat;
      }
      if (that.mapall) {
         that.mapall.setCenter([Lng, Lat]);
         that.mapall.setZoom(15);
         that.mapall.remove(this.marker);
         // 创建一个 Marker 实例：
         this.marker = new AMap.Marker({
            position: new AMap.LngLat(Lng, Lat),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: this.details.alarmName,
            icon: '//yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/positioning-marker.png', // 添加 Icon 图标 URL
            offset: new AMap.Pixel(-20, -40)

         });

         // 将创建的点标记添加到已有的地图实例：
         that.mapall.add(this.marker);
      }else {
         var map = new AMap.Map(that.$refs.container, {
                     resizeEnable: true,
                     center: [Lng, Lat],
                     zoom: 15
                  });
                  that.mapall = map;
         // 创建一个 Marker 实例：
         this.marker = new AMap.Marker({
            position: new AMap.LngLat(Lng, Lat),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: this.details.alarmName,
            icon: '//yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/positioning-marker.png', // 添加 Icon 图标 URL
            offset: new AMap.Pixel(-20, -40)
         });

         // 将创建的点标记添加到已有的地图实例：
         that.mapall.add(this.marker);
      }
    }
  }
};
</script>

<style lang="scss">
.policy-import-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
    padding-top: 14px;
  }
  .statistical-box {
   &-title {
      &-text {
         display: flex;
         align-items: center;
         font-size: 16px;
         font-weight: bold;
         color: #333333;
      }
      &-status {
         padding: 4px 12px;
         font-size: 12px;
         font-weight: 400;
         color: #C94242;
         margin-left: 8px;
         background: rgba(201,66,66,0.3);
         border-radius: 16px;
         border: 1px solid #C94242;
      }
      &-status2 {
         color: #00BC0D;
         background: rgba(0,188,13,0.3);
         border: 1px solid #00BC0D;
      }
      &-time {
         color: #CCCCCC;
         font-size: 14px;
      }
   }
   &-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 24px;
      div {
         width: 45%;
         font-size: 14px;
         color: #333333;
         margin-bottom: 16px;
      }
   }
   &-decoration {
      &-top {
         display: flex;
         justify-content: space-between;
         &-tab {
            display: flex;
            div {
               width: 88px;
               height: 32px;
               line-height: 32px;
               text-align: center;
               font-size: 14px;
               color: #333333;
               cursor: pointer;
               border-radius: 8px 8px 0px 0px;
               border: 1px solid #CCCCCC;
               border-bottom: none;
            }
            &-current {
               color: #fff !important;
               border: 1px solid #4278C9 !important;
               background: #4278C9;
            }
         }
         &-download {
            display: flex;
            align-items: center;
            cursor: pointer;
            color: #0080FF;
            img {
               width: 14px;
               height: 14px;
            }
            span {
               margin-left: 2px;
               text-decoration:underline;
            }
         }
      }
      &-down {
         // display: flex;
         // flex-wrap: wrap;
         // justify-content: space-around;
         padding: 16px 0;
         height: 400px;
         overflow: auto;
         border-radius: 0px 8px 8px 8px;
         border: 1px solid #4278C9;
         &-content {
            width: 128px;
            height: 80px;
            margin-bottom: 8px;
            margin-left: 15px;
            border-radius: 4px;
         }
         &-box {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
         }
      }
   }
  }
}
</style>